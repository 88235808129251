/*----------------------------------------*/
/*  13 - Components - Banner
/*----------------------------------------*/
.banner-area{
	padding: 90px 0 0;
	.banner-item{
		> .banner-img{
			> a{
				img{
					width: 100%;
				}
			}
		}
	}
	[class*="col-"]{
		&:not(:last-child){
			@include breakpoint(max-sm_device){
				.banner-item{
					padding-bottom: 30px;
				}
			}
		}
	}
    &.banner-area-2{
        padding: 90px 0 0;
    }
}
/* ---Banner Area Three--- */
.banner-area-3{
	padding: 90px 0 0;
	[class*="col-"]{
		&:not(:last-child){
			@include breakpoint(max-md_device){
				padding-bottom: 30px;
			}
		}
	}
}
/* ---Kenne's Banner Area Four--- */
.kenne-banner_area-4{
	background-image: url('../images/banner/1-6.jpg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	min-height: 565px;
	position: relative;
	padding-top: 0;
	margin-top: 90px;
	&:before{
		content: "";
		position: absolute;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		@extend %basetransition;
	}
	.banner-content{
		@include absCenter;
		text-align: center;
		width: 32%;
		@include breakpoint(max-xl_device){
			width: 45%;
		}
		@include breakpoint(max-lg_device){
			width: 55%;
		}
		@include breakpoint(max-md_device){
			width: 70%;
		}
		@include breakpoint(max-md_device){
			width: 95%;
		}
		h3,
		p,
		a{
			color: $white-color;
		}
		> h3{
			font-size: 45px;
			margin-bottom: 0;
			padding-bottom: 15px;
			@include breakpoint(max-xs_device){
				font-size: 30px;
			}
		}
		> p{
			margin-bottom: 0;
			padding-bottom: 15px;
		}
		> .contact-us{
			padding-bottom: 25px;
			> a{
				font-weight: 600;
			}
		}
		> .kenne-btn-ps_center{
			> a{
				&.kenne-btn{
					border-radius: 4px;
				}
			}
		}
	}
}
/* ---Banner Area Four--- */
.banner-area-5{
	padding: 90px 0 0;
	.banner-item{
		position: relative;
		.banner-img{
			background-image: url('../images/banner/2-6.jpg');
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			min-height: 330px;
			@include breakpoint(max-xxs_device){
				background-position: left;
			}
		}
		.banner-content{
			text-align: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 20%;
			@include breakpoint(max-xs_device){
				left: 10%;
			}
			@include breakpoint(max-xxs_device){
				@include absCenter;
				width: 100%;
			}
			> span{
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: 4px;
				display: block;
				padding-bottom: 10px;
			}
			> h3{
				margin-bottom: 25px;
				font-size: 28px;
				line-height: 1.2;
				letter-spacing: 4px;
				@include breakpoint(max-md_device){
					font-size: 20px;
				}
			}
			.kenne-btn{
				@include breakpoint(max-md_device){
					width: 100px;
					height: 30px;
					line-height: 29px;
					font-size: 12px;
					text-transform: capitalize;
				}
			}
		}
	}
}