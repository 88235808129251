/*----------------------------------------*/
/*  19 - Components - Animation
/*----------------------------------------*/
/* Animation Style One */
.slick-active {
	& .animation-style-01 {
		.slide-content {
			> span {
				animation-name: fadeInLeft;
				animation-delay: 0.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> h2 {
				animation-name: fadeInLeft;
				animation-delay: 1s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
				
			}
			> p {
				animation-name: fadeInLeft;
				animation-delay: 1.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
			> .slide-btn {
				animation-name: fadeInLeft;
				animation-delay: 2.5s;
				animation-duration: 1.3s;
				animation-fill-mode: both;
			}
		}
	}
}

// Kyframes

/*Top Up Keyframes*/
@-webkit-keyframes kenSlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
@keyframes kenSlideInUp {
	0% {
		-webkit-transform: translateY(250px);
		transform: translateY(250px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}
/*Top Down Keyframes*/

 @-webkit-keyframes kenSlideInDown {
	0% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	}
	100% {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	}
  }
  @keyframes kenSlideInDown {
	0% {
	  -webkit-transform: translateY(0);
			  transform: translateY(0);
	}
	100% {
	  -webkit-transform: translateY(100px);
			  transform: translateY(100px);
	}
  }

  .kenSlideInDown {
	-webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
  
// Begin Slide In Right Kyframes
.slide-in-right {
	-webkit-animation: slide-in-right 4s linear infinite alternate both;
	animation: slide-in-right 4s linear infinite alternate both;
}
/* ----------------------------------------------
 * Generated by Animista on 2019-1-7 17:0:13
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
@keyframes slide-in-right {
	0% {
		-webkit-transform: translateX(340px);
		transform: translateX(340px);
	}
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
}
// Slide In Right Kyframes End Here
