//--- All Variables - Related Stuff Included In This Area ---↓
// Color
$primary-color:     #a8741a;
$primary-color-2:   #df2121;
$primary-color-3:   #e75480;
$primary-color-4:   #ff00ff;
$primary-color-5:   #6a0dad;
$primary-color-6:   #ff007f;
$primary-color-7:   #B03060;
$primary-color-8:   #ffa500;
$primary-color-9:   #8e4585;
$primary-color-10:  #65000b;
$text-color:        #242424;
$border-color:      #e5e5e5;

// Additional Color Variable
$white-color:       #ffffff;
$white-smoke_color: #f5f5f5;
$silver-color:      #bbbbbb;

// Slider Color Swatches
$burnt-orange_color:#ff832b;
$raw-umber_color:   #824900;
$black-color:       #000000;
$brown-color:       #a6311f;
$red-color:         #ff0000;

//----Social Link Color Variables ----↓
$facebook-color:    #3b579d;
$twitter-color:     #3acaff;
$google-plus-color: #dd4b39;
$linkedin-color:    #0097bd;
$pinterest-color:   #cb2027;
$instagram-color:   #833ab4;
$skype-color:       #00aff0;
$share-color:       #fe6d4c;
$youtube-color:     #d72525;

// Template Color Variation ----↓
$color-1: $primary-color;
$color-2: $primary-color-2;
$color-3: $primary-color-3;
$color-4: $primary-color-4;
$color-5: $primary-color-5;
$color-6: $primary-color-6;
$color-7: $primary-color-7;
$color-8: $primary-color-8;
$color-9: $primary-color-9;
$color-10: $primary-color-10;
$colorList: $color-1, $color-2, $color-3, $color-4, $color-5, $color-6, $color-7, $color-8, $color-9, $color-10;
