/*----------------------------------------*/
/*  14 - Components - Product
/*----------------------------------------*/
.product-area{
    padding: 90px 0 0;
    &.pb-90{
        padding-bottom: 90px;
    }
}

.product-item {
    margin: 10px 0;
    .single-product {
        border: 1px solid $border-color;
        padding: 20px;
        @extend %basetransition;
        .product-img {
            position: relative;
            text-align: center;
            > a {
                display: block;
                img{
                    width: 100%;
                }
                .secondary-img {
                    position: absolute;
                    left: 0;
                    top: 0;
                    opacity: 0;
                    width: 100%;
                    @extend %basetransition;
                }
            }
            &:hover {
                .secondary-img {
                    opacity: 1;
                }
            }
            .add-actions {
                background-color: $white-smoke_color;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
                opacity: 0;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                @extend %basetransition;
                z-index: 9;
                > ul {
                    > li {
                        display: block;
                        > a {
                            display: block;
                            width: 0;
                            height: 35px;
                            line-height: 36px;
                            text-align: center;
                            > i {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
        .product-content {
            padding-top: 30px;
            .product-desc_info {
                .price-box {
                    @include new-price;
                    @include old-price;
                }
                .product-category{
                    position: relative;
                    margin-bottom: 10px;
                    padding-left: 30px;
                    a {
                        font-size: 14px;
                        display: block;
                        &:before{
                            content: "";
                            background-color: $silver-color;
                            height: 2px;
                            width: 20px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }
                }
                h3{
                    &.product-name{
                        margin-bottom: 10px;
                        font-size: 16px;
                        font-weight: 400;
                    }
                }
                .rating-box {
                    padding-top: 10px;
                    > ul {
                        > li {
                            display: inline-block;
                            > i {
                                font-size: 18px;
                            }
                        }
                    }
                }
            }
        }
        &:hover {
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            .product-img {
                .add-actions {
                    opacity: 1;
                    > ul{
                        > li{
                            > a{
                                width: 35px;
                            }
                        }
                    }
                }
            }
        }
    }
}

/* ---List Product--- */
.list-product_area{
    padding-top: 80px;
    .section-title{
        margin-bottom: 25px;
        > h3{
            @include breakpoint(max-xxs_device){
                font-size: 16px;
                padding-left: 0;
                padding-right: 10px;
            }
        }
    }
    .list-product_slider{
        .product-item{
            border: 1px solid $border-color;
            margin: 15px 0;
            .single-product{
                display: flex;
                border: 0;
                padding: 20px;
                @include breakpoint(max-xxs_device){
                    display: block;
                }
                .product-img{
                    flex: 0 0 140px;
                }
                .product-content{
                    padding-left: 15px;
                    padding-top: 0;
                    @include breakpoint(max-xxs_device){
                        padding-bottom: 30px;
                    }
                    span{
                        &.manufacture-product{
                            padding-bottom: 5px;
                            display: block;
                        }
                    }
                    .add-actions{
                        @extend %basetransition;
                        opacity: 0;
                        > ul{
                            > li{
                                display: inline-block;
                                > a{
                                    background-color: $white-smoke_color;
                                    padding: 10px;
                                }
                                i{
                                    font-size: 18px;
                                }
                                &:not(:last-child){
                                    margin-right: 5px;
                                }
                                &:nth-child(2){
                                    > a{
                                        padding: 10px 20px;
                                        @include breakpoint(max-md_device){
                                            padding: 10px 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover{
                    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                    .add-actions{
                        opacity: 1;
                        transform: translateY(20px);
                    }
                }
            }
        }
        .slick-list{
            margin: -15px;
        }
    }
}