/*----------------------------------------*/
/*  04 - Utilities - Color
/*----------------------------------------*/
/* ---Background Color--- */
.bg-smoke_color{
    background-color: $white-smoke_color;
}
.bg-white_color{
    background-color: $white-color;
}

@for $i from 1 through length($colorList) {
    .template-color-#{$i} {
        a{
            color: $text-color;
            &:hover{
                color: nth($colorList, $i);
            }
        }
        [class*="active"]{
            > a{
                color: nth($colorList, $i);
            }
        }

        .loading {
            .middle {
                .loader {
                    border: 4px solid nth($colorList, $i);
                }
        
                .loader-inner {
                    background-color: nth($colorList, $i);
                }
            }
        }

        .kenne-btn{
            &:hover{
                background-color: nth($colorList, $i);
            }
            &.transparent-btn{
                border: 1px solid $border-color;
                &:hover{
                    background-color: nth($colorList, $i);
                    border: 1px solid nth($colorList, $i);
                }
                &.transparent-btn-2{
                    color: $text-color !important;
                    text-transform: capitalize;
                    &:hover{
                        color: $white-color !important;
                    }
                }
                &.black-color{
                    border: 1px solid $black-color;
                    color: $black-color !important;
                    &:hover{
                        border-color: nth($colorList, $i);
                        color: $white-color !important;
                    }
                }
            }
        }

        .kenne-group_btn {
            > ul {
                > li {
                    > a {
                        &.add-to_cart {
                            &:hover {
                                background-color: nth($colorList, $i) !important;
                                color: $white-color !important;
                            }
                        }
                        &:hover {
                            border-color: nth($colorList, $i) !important;
                        }
                    }
                }
            }
        }

        .kenne-login_btn,
        .kenne-register_btn {
            &:hover {
                background: nth($colorList, $i);
            }
        }

        .kenne-cart_btn {
            a {
                &:hover {
                    background: nth($colorList, $i);
                    color: $white-color !important;
                }
            }
        }

        .search-btn {
            &:hover {
                color: nth($colorList, $i);
            }
        }

        .reply-btn {
            a {
                color: nth($colorList, $i);
                border: 1px solid nth($colorList, $i);
                &:hover {
                    color: $white-color !important;
                    background-color: nth($colorList, $i);
                }
            }
        }

        .banner-area-5{
            .banner-item{
                .banner-content{
                    > span{
                        color: nth($colorList, $i);
                    }
                }
            }
        }
        
        .form-group{
            .required {
            	color: nth($colorList, $i);
            }
        }

        .kenne-contact-form_btn {
            &:hover {
                background-color: nth($colorList, $i) !important;
                color: $white-color;
                border-color: nth($colorList, $i) !important;
            }
        }

        .about-us_btn {
            background: nth($colorList, $i);
        }
        
        .kenne-product-stock-status {
            span {
                &.in-stock,
                &.out-stock {
                    color: nth($colorList, $i);
                }
            }
        }

        .coupon,
        .coupon2 {
            input {
                &.button {
                    &:hover {
                        background: nth($colorList, $i);
                    }
                }
            }
        }

        .cart-page-total {
            a {
                &:hover {
                    background: nth($colorList, $i);
                    border-color: nth($colorList, $i);
                    color: $white-color !important;
                }
            }
        }

        .coupon-accordion {
            h3 {
                border-top: 3px solid nth($colorList, $i);
            }
            span {
                &:hover {
                    color: nth($colorList, $i);
                }
            }
            .coupon-content {
                .coupon-info {
                    p {
                        &.form-row {
                            input[type="submit"] {
                                &:hover {
                                    background: nth($colorList, $i);
                                }
                            }
                        }
                        &.checkout-coupon {
                            input[type="submit"] {
                                background: nth($colorList, $i);
                            }
                        }
                        &.lost-password {
                            a {
                                &:hover {
                                    color: nth($colorList, $i);
                                }
                            }
                        }
                    }
                }
            }
        }

        .coupon-checkout-content {
            .coupon-info {
                .checkout-coupon {
                    input[type="submit"] {
                        &:hover {
                            background: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .country-select,
        &.checkout-form-list {
            label {
                span {
                    &.required {
                        color: nth($colorList, $i);
                    }
                }
            }
        }

        .panel-title {
			a {
				&:hover {
					color: nth($colorList, $i) !important;
				}
			}
        }
        
        .order-button-payment {
            input {
                &:hover {
                    background: nth($colorList, $i);
                }
            }
        }

        .compare-product-name {
            a {
                &:hover {
                    color: nth($colorList, $i);
                }
            }
        }

        .modal-body{
            button{
                span{
                    @extend %basetransition;
                    &:hover{
                        color: nth($colorList, $i);
                    }
                }
            }
        }

        .btn-close{
            &:hover{
                color: nth($colorList, $i) !important;
            }
        }

        .offcanvas-search{
            button{
                &.search_btn{
                    &:hover{
                        > i{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .main-nav{
            > ul{
                > li{
                    &:hover{
                        > a{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .search-button{
            &:hover{
                color: nth($colorList, $i);
            }
        }

        .tty-slick-text-btn{
            &:hover{
                background-color: nth($colorList, $i);
                color: $white-color !important;
            }
        }

        .minicart-wrap{
            > a{
                &.minicart-btn{
                    .minicart-count_area{
                        > span{
                            &.item-count{
                                background-color: nth($colorList, $i);
                                color: $white-color;
                            }
                        }
                        i{
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .offcanvas-minicart_wrapper{
            .minicart-list {
				> li {
					&.minicart-product {
						.product-item_img {
							&:hover{
								border-color: nth($colorList, $i) !important;
							}
						}
					}
				}
			}
        }

        .megamenu-holder {
            > ul {
                > li {
                    > span {
                        &.megamenu-title {
                            &:before{
                                content: "";
                                background-color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }

        .product-view-mode {
			> a {
				&.active {
					color: nth($colorList, $i);
				}
			}
		}

        .section-title{
            .product-tab{
                > ul{
                    &.product-menu{
                        > li{
                            > a{
                                &.active{
                                    color: nth($colorList, $i);
                                }
                            }
                        }
                    }
                }
            }
        }

        .sp-img_slider-nav {
            .slick-slide{
                &.slick-current{
                    img{
                        border-color: nth($colorList, $i);
                    }
                }
            }
        }

        .kenne-paginatoin-area {
            .kenne-pagination-box {
                &.primary-color{
                    border: 2px solid nth($colorList, $i);
                    > li{
                        &:last-child {
                            > a {
                                border-right: 0;
                            }
                        }
                        &.active{
                            > a{
                                color: nth($colorList, $i);
                            }
                        }
                        > a{
                            border-right: 1px solid nth($colorList, $i);
                            &:hover{
                                color: nth($colorList, $i) !important;
                            }
                        }
                    }
                }
            }
        }

        .tooltip-inner {
			background-color: nth($colorList, $i);
		}
		.tooltip.bs-tooltip-auto[x-placement^=top] .arrow::before,
		.tooltip.bs-tooltip-top .arrow::before {
			border-top-color: nth($colorList, $i);
		}
		.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
		.bs-tooltip-bottom .arrow::before {
			border-bottom-color: nth($colorList, $i);
		}
		.tooltip.bs-tooltip-auto[x-placement^=right] .arrow::before,
		.tooltip.bs-tooltip-right .arrow::before {
			border-right-color: nth($colorList, $i);
		}
		.bs-tooltip-auto[x-placement^=left] .arrow::before,
		.bs-tooltip-left .arrow::before {
			border-left-color: nth($colorList, $i);
        }
        
        .sticker{
            background-color: nth($colorList, $i);
            &:before {
                border-color: transparent transparent transparent nth($colorList, $i);
            }
        }

        .new-price {
            color: nth($colorList, $i);
        }

        .rating-box {
            > ul {
                > li {
                    color: nth($colorList, $i);
                }
            }
        }

        .add-actions {
            > ul {
                > li {
                    > a {
                        &:hover {
                            background-color: nth($colorList, $i) !important;
                            color: $white-color !important;
                        }
                    }
                }
            }
        }

        .footer-widgets {
			> ul {
				> li {
                    > a{
						@extend %basetransition;
						padding-left: 0;
					}
					&:hover{
						> a{
							color: nth($colorList, $i);
							padding-left: 10px;
						}
					}
				}
			}
        }
        
        .newsletter-btn {
			background-color: nth($colorList, $i);
			color: $white-color;
			&:hover {
				background-color: $text-color;
			}
        }
        
        .kenne-social_link {
            > ul {
                > li {
                    > a {
                        &:hover {
                            background-color: nth($colorList, $i);
                            color: $white-color !important;
                        }
                    }
                }
            }
        }

        .account-page-area {
            .myaccount-tab-trigger {
                li {
                    a {
                        &.active {
                            background: nth($colorList, $i);
                            color: $white-color;
                            &:hover {
                                color: $white-color !important;
                            }
                        }
                        &:hover {
                            color: nth($colorList, $i);
                        }
                    }
                }
            }
        }

        .error-content_wrapper {
            .error-area {
                .error {
                    h1 {
                        color: nth($colorList, $i);
                    }
                    .btn {
                        background-color: nth($colorList, $i);
                        &:after {
                            background-color: nth($colorList, $i);
                        }
                    }
                    .searchform {
                        button {
                            &.searchform__submit {
                                background-color: nth($colorList, $i);
                            }
                        }
                    }
                }
            }
        }

        .frequently-accordion {
            .actives {
                border: 1px solid nth($colorList, $i);
            }
            .card-header {
                a {
                    &:hover {
                        color: nth($colorList, $i) !important;
                    }
                }
            }
        }

        .kenne-tags_list {
            li {
                a {
                    &:hover {
                        color: $white-color !important;
                        background-color: nth($colorList, $i);
                        border-color: nth($colorList, $i);
                    }
                }
            }
        }

        .overview-content {
            > h2 {
                &:after {
                    background: nth($colorList, $i);
                }
                span {
                    color: nth($colorList, $i);
                }
            }
        }
        
        .count-title {
			h2 {
				color: nth($colorList, $i);
			}
		}

        .scroll-to-top {
            &:hover {
                background-color: nth($colorList, $i);
            }
        }

    }
}