/*----------------------------------------*/
/*  37 - Pages - Wishlist
/*----------------------------------------*/
.kenne-wishlist_area {
	padding: 100px 0;
}
.table-content {
	table {
		background: $white-color none repeat scroll 0 0;
		border-color: $border-color;
		border-radius: 0;
		border-style: solid;
		border-width: 1px 0 0 1px;
		text-align: center;
		width: 100%;
		margin-bottom: 0;
		th,
		td {
			border-bottom: 1px solid $border-color;
			border-right: 1px solid $border-color;
		}
		th {
			border-top: medium none;
			font-size: 15px;
			text-transform: capitalize;
			padding: 20px 10px;
			text-align: center;
			font-weight: 500;
			vertical-align: middle;
		}
		td {
			border-top: medium none;
			padding: 18px 10px;
			vertical-align: middle;
			font-size: 13px;
			@include breakpoint (max-sm_device) {
				width: 100%;
			}
			&:nth-child(3) {
				@include breakpoint (max-sm_device) {
					padding: 32.1px 10px;
					display: block;
					width: 180px;
				}
			}
			&.kenne-product_remove {
				font-size: 20px;
				> a {
					> i {
						font-size: 14px;
						@include transition(all 0.3s ease-in-out);
					}
				}
			}
			&.kenne-product-name {
				font-size: 16px;
				text-transform: capitalize;
			}
			&.kenne-product-price {
				font-size: 16px;
			}
			&.kenne-product-stock-status {
				span {
					&.in-stock,
					&.out-stock {
						font-size: 12px;
						text-transform: capitalize;
					}
					&.out-stock {
						color: $red-color;
					}
				}
			}
			&.kenne-cart_btn {
				a {
					font-size: 14px;
					text-transform: uppercase;
					background: $text-color;
					color: $white-color;
					height: 40px;
					line-height: 43px;
					width: 130px;
					display: inline-block;
					@extend %basetransition;
				}
			}
		}
	}
}
