/*----------------------------------------*/
/*  15 - Components - Product Tab
/*----------------------------------------*/
.product-tab_area{
    padding: 95px 0 0;
	.section-title{
		display: flex;
		justify-content: space-between;
		margin-bottom: 35px;
		@include breakpoint(max-xxs_device){
			display: block;
			&:before{
				display: none;
			}
			> h3{
				padding-right: 0;
			}
		}
		.product-tab{
			background-color: $white-color;
			display: flex;
			align-items: center;
			z-index: 2;
			padding-left: 25px;
			padding-right: 120px;
			position: relative;
			@include breakpoint(max-xxs_device){
				padding-left: 0;
				padding-top: 15px;
			}
			> ul{
				&.product-menu{
					> li{
						margin-right: 15px;
						&:last-child{
							margin-right: 0;
						}
					}
				}
			}
		}
	}
}

/* ---Kenne's Product Tab Area Two--- */
.product-tab_area-2 {
	background-color: $white-color;
	.short-desc {
		margin: 0 auto;
		text-align: center;
		> p {
			margin-bottom: 0;
			padding-bottom: 15px;
		}
	}
	.product-tab {
		border-bottom: 1px solid $border-color;
		display: flex;
		justify-content: space-between;
		margin-bottom: 30px;
		padding-bottom: 25px;
		@include breakpoint (max-sm_device) {
			display: block;
		}
		.kenne-tab_title {
			@include breakpoint (max-sm_device) {
				padding-bottom: 20px;
			}
			> h4 {
				text-transform: uppercase;
				margin-bottom: 0;
				@include breakpoint (max-md_device) {
					font-size: 20px;
				}
			}
		}
		> ul {
			&.product-menu {
				@include breakpoint(max-xxs_device){
					display: block;
					width: 100%;
					text-align: center;
				}
				> li {
					> a {
						text-transform: uppercase;
						padding: 0 30px;
						position: relative;
						display: block;
						@include breakpoint (max-md_device) {
							padding: 0 15px;
							font-size: 14px;
						}
						@include breakpoint(max-xxs_device){
							padding: 0 0 10px 0;
						}
						&.active{
							color: $primary-color;
						}
						&:after {
							background-color: $silver-color;
							content: "";
							position: absolute;
							top: 50%;
							right: -9px;
							height: 2px;
							width: 18px;
							@include breakpoint (max-md_device) {
								width: 10px;
								right: -5px;
							}
							@include breakpoint (max-xs_device) {
								display: none;
							}
						}
					}
					&:last-child {
						> a {
							padding-right: 0;
							@include breakpoint(max-xxs_device){
								padding: 0;
							}
							&:after {
								display: none;
							}
						}
					}
				}
			}
		}
	}
}
