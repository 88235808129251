/*----------------------------------------*/
/*  12 - Components - Button
/*----------------------------------------*/
/* ---Kenne's Button Position--- */
.kenne-btn-ps_center {
	display: flex;
	justify-content: center;
	&.kenne-btn-ps_left {
		justify-content: flex-start;
	}
	&.kenne-btn-ps_right {
		justify-content: flex-end;
	}
}
.kenne-btn {
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0);
	background-color: $text-color;
    color: $white-color !important;
	width: 175px;
	height: 45px;
	line-height: 45px;
	display: block;
	text-align: center;
	text-transform: uppercase;
	font-weight: 400;
    @extend %basetransition;
    border-radius: 25px;
	font-size: 16px;
	&.transparent-btn{
		background-color: transparent;
	}
	// Fullwidth
	&.kenne-btn_fullwidth{
		width: 100%;
	}
	&.kenne-btn_sm {
		width: 100%;
		height: 40px;
		line-height: 40px;
		font-size: 13px;
	}
	// Color
	&.black-color{
		border: 1px solid $black-color;
		font-size: 14px;
	}
	// Button Style
	&.square-btn{
		border-radius: 0;
	}
}
