/*----------------------------------------*/
/*  17 - Components - Form
/*----------------------------------------*/
/* ---Newsletter Form--- */
.newsletter-form_wrap {
	.subscribe-form {
		position: relative;
		.newsletter-input {
			border: 1px solid $border-color;
			height: 50px;
			width: 100%;
			padding: 0 70px 0 20px;
		}
		.newsletter-btn {
			position: absolute;
			font-size: 21px;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;
			line-height: 50px;
			text-align: center;
			@extend %basetransition;
		}
	}
}