/*----------------------------------------*/
/*  32 - Pages - About Us
/*----------------------------------------*/
.about-us-area {
	padding-top: 100px;
	.overview-content {
		@include breakpoint(max-sm_device){
			padding-top: 25px;
		}
		> h2 {
			font-size: 32px;
			font-weight: 500;
			margin-bottom: 25px;
			padding-bottom: 20px;
			position: relative;
			text-transform: uppercase;
			@include breakpoint (lg-device) {
				font-size: 30px;
			}
			@include breakpoint (md-device) {
				font-size: 26px;
			}
			@include breakpoint (max-sm_device) {
				font-size: 20px;
			}
			@include breakpoint (max-xxs_device) {
				font-size: 18px;
				line-height: 24px;
			}
			&:before {
				background-color: $white-smoke_color;
				bottom: 0;
				content: "";
				height: 2px;
				left: 0;
				position: absolute;
				@extend %basetransition;
				width: 100%;
			}
			&:after {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				height: 2px;
				width: 170px;
			}
		}
		> .short_desc {
			margin-bottom: 0;
			padding-bottom: 25px;
		}
		.kenne-about-us_btn-area {
			@include breakpoint (max-sm_device) {
				float: left;
				width: 100%;
			}
			.about-us_btn {
				color: $white-color;
				display: block;
				float: left;
				height: 50px;
				line-height: 50px;
				width: 145px;
				text-align: center;
				border-radius: 3px;
				transition: all 0.3s ease-in-out;
				&:hover {
					background: $text-color;
					color: $white-color !important;
				}
			}
		}
	}
}

// Kenne's CounterUp
.project-count-area {
	padding-top: 95px;
	.single-count {
		&:hover {
			.count-icon {
				span {
					animation: 850ms ease-in-out 0s normal none 1 running tada;
				}
			}
		}
		.count-icon {
			span {
				font-size: 50px;
				color: #595959;
				line-height: 1;
				display: inline-block;
			}
		}
		.count-title {
			h2 {
				font-size: 40px;
				font-weight: bold;
				letter-spacing: 0.2px;
				margin: 15px 0 5px;
			}
			> span {
				font-size: 20px;
				text-transform: capitalize;
			}
		}
	}
	[class*="col-"] {
		&:nth-child(-n + 2) {
			.single-count {
				@include breakpoint (md-device) {
					padding-bottom: 30px;
				}
				@include breakpoint (sm-device) {
					padding-bottom: 30px;
				}
			}
		}
		@include breakpoint (max-xs_device) {
			padding-bottom: 30px;
		}
		&:last-child {
			@include breakpoint (max-xs_device) {
				padding-bottom: 0;
			}
		}
	}
}

// Kenne's Team Member
.team-area {
	padding-top: 90px;
	padding-bottom: 100px;
	.section_title-2 {
		padding-bottom: 25px;
		> h3{
			margin-bottom: 0;
		}
	}
	.team-member {
		.team-thumb {
			img {
				width: 100%;
				display: block;
				overflow: hidden;
			}
		}
		.team-content {
			padding-top: 25px;
			h3 {
				font-size: 20px;
				line-height: 28px;
				text-transform: capitalize;
				margin-bottom: 0;
				padding-bottom: 10px;
			}
			p {
				margin-bottom: 0;
				padding-bottom: 15px;
			}
			.kenne-social_link {
				padding-top: 20px;
				> ul {
					> li {
						padding-right: 5px;
						> a {
							width: 35px;
							height: 35px;
							line-height: 33px;
							@include breakpoint (max-lg_device) {
								width: 30px;
								height: 30px;
								line-height: 30px;
							}
						}
					}
				}
			}
		}
	}
	[class*="col-"] {
		&:nth-child(-n + 2) {
			.team-member {
				@include breakpoint (md-device) {
					padding-bottom: 30px;
				}
				@include breakpoint (sm-device) {
					padding-bottom: 30px;
				}
			}
		}
		&:not(:last-child) {
			.team-member {
				@include breakpoint (max-xs_device) {
					padding-bottom: 30px;
				}
			}
		}
	}
}
